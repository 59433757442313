/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'Accordion',
        import: () => import('@stories/Widgets/Accordion/Accordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'AnchorTarget',
        import: () => import('@stories/Widgets/AnchorTarget/AnchorTarget'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'BookNowCta',
        import: () => import('@stories/Widgets/BookNow/BookNow'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CategoryCards',
        import: () => import('@stories/Widgets/CategoryCards/CategoryCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CheckerboardImageText',
        import: () => import('@stories/Widgets/CheckerboardImageText/CheckerboardImageText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ContactUs',
        import: () => import('@stories/Widgets/ContactUs/ContactUs'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CountdownHeroBanner',
        import: () => import('@stories/Widgets/CountdownHeroBanner/CountdownHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CtaCard',
        import: () => import('@stories/Widgets/CtaCard/CtaCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventCalendar',
        import: () => import('@stories/Widgets/EventCalendar/EventCalendar'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventDetail',
        import: () => import('@stories/Widgets/EventDetail/EventDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'EventList',
        import: () => import('@stories/Widgets/EventsList/EventsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FactsCarousel',
        import: () => import('@stories/Widgets/FactsCarousel/FactsCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Floorplans',
        import: () => import('@stories/Widgets/FloorPlan/FloorPlan'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Footer/Footer'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'FullScreenVideo',
        import: () => import('@stories/Widgets/FullScreenVideo/FullScreenVideo'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Header',
        import: () => import('@stories/Widgets/Header/Header'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'HeroInner',
        import: () => import('@stories/Widgets/HeroInner/HeroInner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'HomeHeroBanner',
        import: () => import('@stories/Widgets/HomeHeroBanner/HomeHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Image',
        import: () => import('@stories/Widgets/Image/Image'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ImageGallery',
        import: () => import('@stories/Widgets/ImageGallery/ImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCard',
        import: () => import('@stories/Widgets/InformationCard/InformationCard'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'InformationCarousel',
        import: () => import('@stories/Widgets/InformationCarousel/InformationCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Introduction',
        import: () => import('@stories/Widgets/Introduction/Introduction'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestNews',
        import: () => import('@stories/Widgets/LatestNews/LatestNews'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LatestNewsEvents',
        import: () => import('@stories/Widgets/LatestNewsEvents/LatestNewsEvents'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LevelGuide',
        import: () => import('@stories/Widgets/LevelGuide/LevelGuide'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocationMap',
        import: () => import('@stories/Widgets/LocationMap/LocationMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LogoStrip',
        import: () => import('@stories/Widgets/LogoStrip/LogoStrip'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'MosaicSplitContent',
        import: () => import('@stories/Widgets/MosaicSplitContent/MosaicSplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsDetail',
        import: () => import('@stories/Widgets/NewsDetail/NewsDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsEventList',
        import: () => import('@stories/Widgets/NewsEventList/NewsEventList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NewsList',
        import: () => import('@stories/Widgets/NewsList/NewsList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OccupiersList',
        import: () => import('@stories/Widgets/OccupierList/OccupierList'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'PageNotFound',
        import: () => import('@stories/Widgets/PageNotFound/PageNotFound'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'QuotedPanel',
        import: () => import('@stories/Widgets/QuotedPanel/QuotedPanel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RevealImagesSplitContent',
        import: () => import('@stories/Widgets/RevealImagesSplitContent/RevealImagesSplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText5050',
        import: () => import('@stories/Widgets/RichText5050/RichText5050'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollingImageGallery',
        import: () => import('@stories/Widgets/ScrollingImageGallery/ScrollingImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollingText',
        import: () => import('@stories/Widgets/ScrollingText/ScrollingText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShareButtons',
        import: () => import('@stories/Widgets/ShareButtons/ShareButtons'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShardLightsBanner23',
        import: () => import('@stories/Widgets/ShardLightsBanner23/ShardLightsBanner23'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ShardLightsHeroBanner',
        import: () => import('@stories/Widgets/ShardLightsHeroBanner/ShardLightsHeroBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostCards',
        import: () => import('@stories/Widgets/SignpostCards/SignpostCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Sitemap',
        import: () => import('@stories/Widgets/SiteMap/SiteMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SkipToContent',
        import: () => import('@stories/Widgets/SkipToContent/SkipToContent'),
        config: {
            hydrate: 'never',
        },
    },
    {
        name: 'SoundsOfLondon',
        import: () => import('@stories/Widgets/SoundsOfLondon/SoundsOfLondon'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SpaceSpecifications',
        import: () => import('@stories/Widgets/SpaceSpecification/SpaceSpecification'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Statistics',
        import: () => import('@stories/Widgets/Statistics/Statistics'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StickyNavigation',
        import: () => import('@stories/Widgets/StickyNavigation/StickyNavigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'SustainabilityFacts',
        import: () => import('@stories/Widgets/SustainabilityFacts/SustainabilityFacts'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'TabbedAccordion',
        import: () => import('@stories/Widgets/TabbedAccordion/TabbedAccordion'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedContent',
        import: () => import('@stories/Widgets/TabbedComponent/TabbedComponent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedMedia',
        import: () => import('@stories/Widgets/TabbedMedia/TabbedMedia'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TestimonialQuote',
        import: () => import('@stories/Widgets/BlockQuote/BlockQuote'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Timeline',
        import: () => import('@stories/Widgets/Timeline/Timeline'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RevealImagesSplitContent',
        import: () => import('@stories/Widgets/RevealImagesSplitContent/RevealImagesSplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'UpcomingEvents',
        import: () => import('@stories/Widgets/UpcomingEvents/UpcomingEvents'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CheckerboardImageText',
        import: () => import('@stories/Widgets/CheckerboardImageText/CheckerboardImageText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoCarousel',
        import: () => import('@stories/Widgets/VideoCarousel/VideoCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollingImageGallery',
        import: () => import('@stories/Widgets/ScrollingImageGallery/ScrollingImageGallery'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoPlayer',
        import: () => import('@stories/Widgets/VideoPlayer/VideoPlayer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoPlayerSimple',
        import: () => import('@stories/Widgets/VideoPlayerSimple/VideoPlayerSimple'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ViewingSpotsMap',
        import: () => import('@stories/Widgets/ViewingSpotsMap/ViewingSpotsMap'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VirtualTour',
        import: () => import('@stories/Widgets/VirtualTour/VirtualTour'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'OfficeBenefitsImageCarousel',
        import: () => import('@stories/Widgets/OfficeBenefitsImageCarousel/OfficeBenefitsImageCarousel'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LocalInfoAndTransport',
        import: () => import('@stories/Widgets/LocalInfoAndTransport/LocalInfoAndTransport'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'VideoBanner',
        import: () => import('@stories/Widgets/VideoBanner/VideoBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StickyCTABar',
        import: () => import('@stories/Widgets/StickyCTABar/StickyCTABar'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'CTABanner',
        import: () => import('@stories/Widgets/CTABanner/CTABanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedImages',
        import: () => import('@stories/Widgets/TabbedImages/TabbedImages'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'CTAImageBanner',
        import: () => import('@stories/Widgets/CTAImageBanner/CTAImageBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'TabbedOfficeContent',
        import: () => import('@stories/Widgets/TabbedOfficeContent/TabbedOfficeContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ScrollingText',
        import: () => import('@stories/Widgets/ScrollingText/ScrollingText'),
        config: {
            hydrate: 'in-view',
        },
    },
];
